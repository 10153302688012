@font-face {
    font-family: Harrington;
    src: url(../font/HARNGTON.TTF);
}

@font-face {
    font-family: Gabriola;
    src: url(../font/Gabriola.ttf);
}

@font-face {
    font-family: "Times New Roman";
    src: url(../font/timesbd.ttf);
    font-weight: bold;
}

div.content {
    max-width: 750px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

h1 {
    font-family: Harrington;
}

p.imgDesc, p.intro {
    font-family: Gabriola;
    font-size: 24px;
}

p.contactInfo, h3 {
    font-family: "Times New Roman";
    font-size: 20px;
    text-align: center;
}

p {
    font-weight: bold;
    text-align: left;
}

img {
    max-width: 100%;
}

#sewblessedImg {
    content: url('../img/sewblessed.jpg');
}

#bridal01Img {
    content: url('../img/bridal01.jpg');
}

#bridal02Img {
    content: url('../img/bridal02.jpg');
}

#bridal03Img {
    content: url('../img/bridal03.jpg');
}

#bride01Img {
    content: url('../img/bride01.jpg');
}

#bride02Img {
    content: url('../img/bride02.jpg');
}

#bride03Img {
    content: url('../img/bride03.jpg');
}

#quilt04Img {
    content: url('../img/quilt04.jpg');
}

#quilt05Img {
    content: url('../img/quilt05.jpg');
}

#quilt06Img {
    content: url('../img/quilt06.jpg');
}

#quilt07Img {
    content: url('../img/quilt07.jpg');
}

#design02Img {
    content: url('../img/design02.jpg');
}

#design04Img {
    content: url('../img/design04.jpg');
}

#pillow01Img {
    content: url('../img/pillow01.jpg');
}

#repair01aImg {
    content: url('../img/repair01a.jpg');
}

#repair01bImg {
    content: url('../img/repair01b.jpg');
}

#repair02aImg {
    content: url('../img/repair02a.jpg');
}

#repair02bImg {
    content: url('../img/repair02b.jpg');
}

#lesson02Img {
    content: url('../img/lesson02.jpg');
}

#lesson03Img {
    content: url('../img/lesson03.jpg');
}

#lesson05Img {
    content: url('../img/lesson05.jpg');
}

#lesson06Img {
    content: url('../img/lesson06.jpg');
}

#last01Img {
    content: url('../img/last01.jpg');
}

#last02Img {
    content: url('../img/last02.jpg');
}

#last03Img {
    content: url('../img/last03.jpg');
}

#last04Img {
    content: url('../img/last04.jpg');
}

#last05Img {
    content: url('../img/last05.jpg');
}
